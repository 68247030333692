import {STATUS} from "assets/js/constants/constant";
import * as https from "https";

export default ({$axios, error: NuxtError, i18n, store, route, app, localePath, req}, inject) => {

  if (process.client) {
    window['console'].log('%ceveride 🏃‍♂️ ⛷ 🏕', 'font-weight: bold; font-size: 30px;color: rgb(53, 200, 134); text-shadow: 3px 3px #335360');
    window['console'].groupCollapsed('%cDevelopment Team', 'color:white;font-weight:bold;');
    window['console'].log('%cRejoignez-nous  ⃕', 'margin-left:10px; font-weight: normal; font-size: 20px;color: white; text-shadow: 1px 1px black');
    window['console'].log('%chello@everide.app', 'margin-left:10px;');
    window['console'].groupEnd();
  }

  const api = $axios.create({
    ...process.env.NODE_ENV === 'development' && {
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      })
    }
  });

  api.onRequest(config => {

    // if(config.url.match(/home/))
    //   console.error('Requête envoyée vers : ', config);


    // Get config country
    const appConfig = store.getters['config/getConfig']
    const buildInfos = store.getters['config/getBuild']
    let country = appConfig?.app?.location?.country_iso
    // Get user country
    if (app.$auth?.loggedIn) {
      country = app.$auth.user?.addresses?.deliveryAddress?.country?.isoCode
    }

    // Create obj localization
    let localization = i18n.locale
    if (country) {
      localization += `-${country}`
    }


    // Update config
    if(process.env.mobileApp && config.url.match(/account|banking|conversations|notifications|purchase|ride|auth/))
      config.headers = {...config.headers, 'X-localization' : localization, 'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0', 'Pragma' : 'no-cache', 'Expires' : 0}
    else
      config.headers = {...config.headers, 'X-localization' : localization }



    if (process.env.mobileApp) {
      config.headers = {...config.headers, ...$axios.defaults.headers.common}
      if (buildInfos) {
        config.headers = {...config.headers, 'X-version': buildInfos.version}
      }
    }
  });

  api.onResponse(response => {
    const isPDF = response.headers['content-type'] === 'application/pdf';
    if (response.status === 200) {
      if (response.data.status === STATUS.success) {
        response.data = response.data.response;
      } else if (!isPDF) {
        response.data = {
          error: true,
          message : response.data.message
        };
      }
    } else {
      response.data = {
        error: true
      };
    }
  });

  api.onError(error => {
    //console.error('Erreur de requête : ', error);
    if(error.response) {
      return Promise.resolve({
        data : {
          error: true,
          ...error.response.data,
          status : error.response.status,
        },
        status: error.response
      });
    }else {
      return Promise.resolve({
        data : {
          error: true,
        }
      });
    }
  });

  inject('api', api);
}
